<template>
  <div>
    <div class="logotitle-top">
      <div>
        <img class="logotitle" src="https://img.hsx777.cn/webjiihee/logotitle.png">
        <button @mousemove="appear = 1" @mouseout="appear = null">登录平台 <img src="https://img.hsx777.cn/webjiihee/right_arrow.png" /> </button>
        <div class="QR-code-box-one" v-show="appear ==1">
          <img src="https://img.hsx777.cn/webjiihee/QR-code.png">
          <p>扫码打开</p>
        </div>
      </div>
    </div>
    <div class="succeed">
     <div>
       <img :src="img">
     </div>
      <div>
        <p>提交成功</p>
        <p>
          厚思想将在2个工作日内联系您，以便核实您的申请，请注意接听来电！
        </p>
        <button @click="rollback">回到首页</button>
      </div>
    </div>
    <div class="wire"></div>
    <div class="introduce">
        <div class="introduce-top">
          <div class="description">
            <img src="https://img.hsx777.cn/webjiihee/logotitle.png">
            <div>一个专注于改装车后市场服务升级的团队，具备丰富的汽车改装行业背景，对行业痛点、优化方向及实现路径具有深刻认识。通过打造一站式采购、销售、技术信息平台重塑汽车后市场服务及配件行业业态；通过输出专业的汽车改装文化以及研发汽车配件、房车露营衍生品扩大品牌影响力。</div>
          </div>
          <div class="introduce-classification-box">
            <p>联系我们</p>
            <div class="introduce-classification">
              <img src="https://img.hsx777.cn/webjiihee/phone.png">
              <span>陈先生-13123377878</span>
            </div>
            <div class="introduce-classification">
              <img src="https://img.hsx777.cn/webjiihee/E-mail.png">
              <span>chenzhihe@jiihee.com</span>
            </div>
            <div class="introduce-classification">
              <img src="https://img.hsx777.cn/webjiihee/site.png">
              <span>厦门市思明区软件园二期望海路23号楼之一</span>
            </div>
          </div>
          <div class="QR-code-box">
            <img src="https://img.hsx777.cn/webjiihee/QR-code.png">
            <p>微信扫码添加</p>
          </div>
        </div>
      </div>
    <div class="introduce-bottom">
        <p>版权：厦门厚思想工业设计有限公司</p>
        <p>备案：闽ICP备2021009637号-2</p>
      </div>
  </div>

</template>
<script>
export default {
  data(){
    return{
      img:require('@/assets/succeed.png'),
      appear:null,
    }
  },
  methods:{
    rollback(){
      this.$router.push({path:'/'})
    }
  }
}
</script>
<style lang="less">
.logotitle-top{
  width: 1920px;
  padding: 19px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  margin: 0 auto;
  &>div{
    width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    button {
      width: 147px;
      height: 45px;
      background: #527E69;
      border-radius: 23px;
      font-size: 19px;
      font-weight: 400;
      color: #FFFFFF;
      border: none;
      vertical-align: middle;

      img {
        width: 19px;
        height: 19px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }
}
.wire{
  width: 100%;
  margin-top: 193px;
  height: 0;
  border: 2px solid #EDF1F5;
}
.succeed{
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
  display: flex;
  width: 1440px;
  img{
    width: 431px;
    height: 364px;
  }
  p:nth-of-type(1){
    height: 60px;
    font-size: 44px;
    font-weight: 600;
    color: #09172F;
    line-height: 60px;
    margin-top: 26px;
  }
  p:nth-of-type(2){
    width: 526px;
    margin: 0 auto;
    margin-top: 44px;
    height: 112px;
    font-size: 28px;
    font-weight: 400;
    color: #516C95;
    line-height: 40px;
  }
  button{
    height: 29px;
    font-size: 21px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 29px;
    border: none;
    width: 175px;
    height: 53px;
    background: #5B8EDB;
    border-radius: 25px;
  }
}
</style>