<template>
  <div class="agreement">
    <div class="message">
      <p>更新时间：2022年4月14日</p>
      <p>生效时间：2022年4月14日</p><br>
      <p>用户使用协议：</p><br>
      <p class="title">应用名称：己合</p>
      <p class="title">所属开发者：厦门厚思想工业设计有限公司</p>
      <p class="title">官网地址：https://m.jiihee.com</p>
      <p class="title">联系邮箱：info@jiihee.com</p><br>
      <p>1. 特别提示</p>
      1.1为使用本手机应用软件及服务，您应当阅读并遵守《用户使用协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及同意或使用某项服务的单独协议，并选择接受或不接受。<br>
      1.2 除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及相关服务。您的下载、安装、使用、获取账号、登录等行为即视为您已阅读并同意上述协议的约束。<br>
      1.3厦门厚思想工业设计有限公司（以下称“本公司”）同意按照本协议的规定及其不时发布的操作规则提供基于互联网的相关服务（以下称"本服务"）。若您需要获得本服务，您（以下称"用户"）应当同意本协议的全部条款并按照页面上的提示完成全部的申请程序。<br>
      <p>2. 协议的范围</p>
      2.1 协议适用主体范围<br>
      本协议是用户与本公司之间关于用户下载、安装、使用、复制本软件，以及使用本公司相关服务所订立的协议。<br>
      2.2 协议关系和冲突条款<br>
      本公司在网站上公布的服务条款及用户所参加课程的招生方案和班次协议等是本协议的补充。本协议与上述内容存在冲突的，以本协议为准。 本协议内容同时包括东大正保公司可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，用户若继续使用本公司软件及服务同样应当遵守。<br>
      <p>3. 服务内容与授权使用范围</p>
      3.1本软件根据用户实际需求提供服务，例如下单购买、浏览产品信息等。本公司保留随时变更、中断或终止部分或全部本服务的权利。<br>
      3.2本软件手机应用的授权使用范围：<br>
      1) 用户可以在手机上安装、使用、显示、运行本软件。<br>
      2) 保留权利：未明示授权的其他一切权利均由本公司所有。<br>
      <p>4. 使用规则</p>
      4.1账号使用规则<br>
      4.1.1用户在使用本软件前需要注册一个账号。用户注册时需要提供准确的个人资料，如个人资料有任何变动，需要及时更新。如因注册信息不准确而引起的问题由用户本人承担，本公司不负任何责任并有权暂停或终止用户的账号。<br>
      4.1.2本软件账号的所有权归本公司所有，用户完成申请注册手续后，仅获得账号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。<br>
      4.1.3如若有任何第三方向本公司发出指示，在确认其提供账户、密码信息准确无误的情况下，用户同意且本公司有权视该行为获得了用户的充分授权，该行为所产生结果直接归属于用户本身。<br>
      4.1.4用户承担账号与密码的保管责任，用户对以其账号发生的或通过其账号发生的一切活动和事件（包括但不限于用户发表的任何内容以及由此产生的任何结果）负全部法律责任。用户须重视账号与密码的保护，若因为用户自身原因，而导致账号与密码遭他人非法使用时，本公司将不承担任何责任。用户自身原因包括但不限于：任意向第三者透露账号和密码及所有注册资料；多人共享同一个账号；安装非法或来路不明的程序等。<br>
      4.2用户使用规则<br>
      4.2.1用户在使用本软件时，必须遵循以下原则：<br>
      (1) 遵守中国有关的法律和法规；<br>
      (2) 不得为任何非法目的而使用本服务系统；<br>
      (3) 遵守所有与本服务有关的网络协议、规定和程序；<br>
      (4) 不得利用本软件系统进行任何可能对互联网的正常运转造成不利影响的行为；<br>
      (5) 不得利用本软件服务系统进行任何不利于其他用户的行为；<br>
      (6) 如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通告东大正保官方；<br>
      (7) 不使用外挂或者其他作弊手段、不正当或不公平手段参加本软件提供的本服务。<br>
      4.2.2本公司致力于为用户提供文明健康、规范有序的服务，用户应遵守中华人民共和国相关法律法规(如果用户是中华人民共和国境外的使用者，还应遵守所属国家或地区的法律法规)，用户将自行承担用户所发布的信息内容的责任。特别地，用户不得发布下列内容：<br>
      (1) 反对中华人民共和国宪法所确定的基本原则的；<br>
      (2) 危害国家统一、主权和领土完整的；<br>
      (3) 泄露国家秘密，危害国家安全或者损害国家荣誉和利益的；<br>
      (4) 煽动民族仇恨、民族歧视，破坏民族团结或者侵害民族风俗、习惯的；<br>
      (5) 破坏国家宗教政策，宣扬邪教、迷信的；<br>
      (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
      (7) 散布淫秽、赌博、暴力或者教唆犯罪的；<br>
      (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br>
      (9) 危害社会公德或者民族优秀文化传统的；<br>
      (10) 宣扬外挂,私服以及木马的相关内容；<br>
      (11) 刊登任何经本公司合理判断为不妥当或者本公司未认可的软件、文件等在内的主页地址或者链接的行为；<br>
      (12) 含有中华人民共和国法律、行政法规禁止的其他内容的。<br>
      <p>5. 知识产权</p>
      5.1本公司是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于视频课件、文字、图片、音频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律<br>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value2: '',
    }
  }
}

</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hear{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.message{
  padding: 130px 20px 30px 20px;
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  p{
    margin: 30px 0 15px 0;
    font-size: 26px;
    font-weight: 600;
  }
}
.title{
  font-weight: 650;
}
</style>
