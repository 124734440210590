<template>
  <div class="privacy">
    <div class="message">
      <p>更新时间：2022年4月14日</p>
      <p>生效时间：2022年4月14日</p><br>
      <p>己合隐私政策</p><br>
      <p class="title">应用名称：己合</p>
      <p class="title">所属开发者：厦门厚思想工业设计有限公司</p>
      <p class="title">官网地址：https://m.jiihee.com</p>
      <p class="title">联系邮箱：info@jiihee.com</p><br>
      <p>引言</p><br>
      <p>欢迎您使用己合！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《己合隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
      本隐私政策将帮助您了解以下内容：</p><br>
      <p>一、我们如何收集和使用您的个人信息</p><br>
      <p>二、我们如何使用Cookies和同类技术</p><br>
      <p>三、我们如何对外提供您的个人信息</p><br>
      <p>四、我们如何保护和保存您的个人信息</p><br>
      <p>五、您如何管理个人信息</p><br>
      <p>六、未成年人的个人信息保护</p><br>
      <p>七、通知和修订</p><br>
      <p>八、如何联系我们</p><br>
      本隐私政策与您所使用的己合服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，以便您更好地理解。<br>
      <br>
      <p>一、我们如何收集和使用您的个人信息</p><br>
      个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。<br>
      我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。<br>
      （一）您须授权我们收集和使用您个人信息的情形<br>
      我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：<br>
      1、实现网上购物所必须的功能<br>
      （1）账号注册、登录与验证<br>
      当您注册己合账号时，您至少需要向我们提供您准备使用的己合账号名、密码、手机号码、电子邮箱，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效，通过SIM卡信息识别并显示对应的运营商。您的账号名为您的默认昵称，您可以修改和补充您的昵称、性别、生日以及您的实名认证相关信息，这些信息均属于您的“账号信息”。您补充的账号信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用网上购物的基本功能。<br>
      当您成功注册己合账号后，可以使用己合账号登录“己合商城”，登录时您需要向我们提供您的己合账号信息。若您不提供这类信息，您将无法登录和在“己合商城”购买商品，此时您可使用浏览和搜索功能，我们将不会收集您的上述个人信息。<br>
      （2）商品/服务信息展示和搜索<br>
      为了向您展示商品或者服务，我们会收集您的设备信息（包括设备名称、设备型号、MAC地址及IMEI、设备识别码、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、设备所在位置相关信息（包括您授权的GPS位置信息以及WLAN接入点、蓝牙和基站传感器信息）、浏览器类型来对相应的商品或者服务内容进行排序以适应您的设备界面。<br>
      为了向您提供搜索历史、浏览记录、收藏的商品或店铺、关注的店铺、购物车或订单商品或服务展示服务，我们会收集您的浏览信息、搜索记录、收藏记录、关注记录、加购信息、订单信息，并根据这些信息进行数据分析、预测您的偏好特征，在己合服务或者其他第三方应用中向您推送您可能感兴趣的商业广告、商业性短信及其他营销信息。<br>
      如您需修改向您展示的商品或服务排序或者商业性广告及其他内容，可通过以下方式实现：<br>
      1）如您对首页“推荐”展示的商品或服务不感兴趣，可以通过长按被推荐的商品或服务图片，根据提示弹窗选择减少或者屏蔽相关商品或服务类目；<br>
      2）如您不想接收搜索页为您综合推荐的商品或服务，可以选择按销量、价格或者其他页面可选的维度接收相应的搜索结果展示；<br>
      3）如您不想接收商业性短信，可以根据短信内容提示，来取消我们给您发送的手机促销短信；<br>
      4）如您不想接收APP发送的消息通知，可以通过移动端APP“消息中心-消息设置”设置；<br>
      （3）下单及订单管理<br>
      当您准备对您购物车内的商品进行结算时，己合系统会生成您购买商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式；您可以另外填写收货人的联系电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票的电子邮箱。<br>
      上述所有信息构成您的“订单信息”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。<br>
      （4）支付功能<br>
      在您下单后，您可以选择己合的关联方或与己合合作的第三方支付机构（包括微信支付及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的己合订单号与交易金额信息，包括您的银行卡号、有效期及其他银行卡（含储蓄卡、信用卡）支付必要信息与这些支付机构共享以实现其确认您的支付指令并完成支付。<br>
      （5）交付产品与/或服务功能<br>
      在当您下单并选择货到付款或在线完成支付、与己合合作的商家或第三方配送公司（以下称“配送公司”）将为您完成订单的交付。您知晓并同意，己合、己合的关联方、供应商、与己合合作的商家或配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。<br>
      我们的配送员在为您提供配送服务的同时，基于某些业务法律要求实名认证的需求，会协助您完成实名认证。我们在此环节会使用您的身份证通过国家有权认证机构的专有设备对您的身份进行核验，我们不会收集您的身份证信息，且我们的配送员或第三方配送公司配送员均须遵守公司保密制度的规定。<br>
      （6）客服与售后功能<br>
      我们的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。<br>
      若您有获赠或购买商品运费险，您在退换货时所产生的运费可能申请由运费险服务提供商进行赔付，为实现投保和理赔，我们将收集或查询您购买商品的质保起止期、退换货商品快递公司、快递单号、退货地址、投递时间信息。<br>
      2、保障交易安全所必须的功能<br>
      为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对己合系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用己合服务过程中对您的服务及账号安全进行保护。<br>
      （二）您可自主选择提供的个人信息的情形<br>
      为使您购物更便捷或更有乐趣，从而提升您在己合的网上购物体验，我们在扩展功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用为您带来购物乐趣的扩展功能或在购买某些商品时需要重复填写一些信息。<br>
      请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。<br>
      （三）我们从第三方获得您个人信息的情形<br>
      我们可能从第三方获取您授权共享的账号信息（头像、昵称及授权页面提示的其他信息），并在您同意本隐私政策后将您的第三方账号与您的己合账号绑定，使您可以通过第三方账号直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。您也可以使用本机号码来快捷登录，我们会将您的网络信息提供给运营商完成注册或登录。<br>
      （四）您个人信息使用的规则<br>
      1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。<br>
      2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。<br>
      3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。<br>
      4、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。<br>
      请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。<br>
<br>
      <p>二、我们如何使用Cookies和同类技术</p><br>
      （一）Cookies的使用<br>
      1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账号或数据安全。<br>
      2、我们不会将Cookies用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问己合网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。<br>
      （二）网络Beacon和同类技术的使用<br>
      除Cookies外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF文件或"网络Beacon"）。我们使用网络Beacon的方式有：<br>
      （1）通过在己合网站上使用网络Beacon，计算用户访问数量，并通过访问Cookies辨认注册的己合用户。<br>
      （2）通过得到的Cookies信息，为您提供个性化服务。<br>
<br>
      <p>三、我们如何对外提供您的个人信息</p><br>
      （一）共享<br>
      1、我们不会与己合以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：<br>
      （1）事先获得您明确的同意或授权；<br>
      （2）根据法律法规、行政及司法部门强制性要求进行提供；<br>
      （3）在法律法规允许的范围内，为维护己合、己合的关联方或合作伙伴、您或其他己合用户或社会公众利益、财产或安全免遭损害而有必要提供；<br>
      （4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；<br>
      （5）应您需求为您处理您与他人的纠纷或争议；<br>
      （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；<br>
      （7）基于学术研究而使用；<br>
      （8）基于符合法律法规的社会公共利益、突发公共卫生事件而使用。<br>
      2、为向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账号与交易安全，我们可能会将您的个人信息与我们的关联方或者合作伙伴共享，我们的关联方或者合作伙伴如要改变个人信息的处理目的，将再次征求您的授权同意。<br>
      请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。<br>
      （二）委托处理<br>
      我们可能委托授权合作伙伴处理您的个人信息，以便向您提供相应的产品或服务。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品或服务所必要的个人信息。如果我们的合作伙伴将您的个人信息用于我们未委托的用途，其将单独征得您的同意。<br>
      我们的合作伙伴包括以下类型：<br>
      （1）提供技术服务的供应商。我们可能会将您的个人信息提供给支持我们功能的第三方，包括为我们提供基础设施技术服务、数据分析服务及数据处理服务等的供应商。<br>
      （2）广告、分析服务类合作伙伴。我们会遵守法律法规及行业通用安全技术，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。<br>
      对我们委托合作伙伴处理个人信息的，我们会与其签署数据保护协议，要求其按照协议约定、本隐私政策以及相关法律法规要求来处理个人信息。<br>
      （三）转移<br>
      在因合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式，要求接收您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。<br>
      （四）公开披露<br>
      我们仅会在以下情况，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：<br>
      （1）根据您单独同意的授权范围披露相应个人信息；<br>
      （2）如果您严重违反法律法规或者相关协议、规则，我们可能会披露您的相关违规行为及我们对您采取的措施。<br>
<br>
      <p>四、我们如何保护和保存您的个人信息</p><br>
      （一）我们保护您个人信息的技术与措施<br>
      我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：<br>
      1、数据安全技术措施<br>
      我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。例如：己合的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全；己合采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离；在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性；己合采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用；己合采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。<br>
      2、己合为保护个人信息采取的其他安全措施<br>
      己合通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；<br>
      我们仅允许有必要知晓这些信息的己合及己合关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。<br>
      互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过己合与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。<br>
      3、安全事件处置<br>
      为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。<br>
      一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br>
      如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本隐私政策“八、如何联系我们”中明确的联系方式联络我们，以便我们采取相应措施。<br>
      （二）您个人信息的保存<br>
      1、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独获取您的授权同意并要求接收方按照双方签署的数据保护协议、本隐私政策以及相关法律法规要求来处理您的个人信息。<br>
      2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。<br>
      3、请您注意，当您成功注销己合账号后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。<br>
      4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。<br>
<br>
      <p>五、您如何管理您的个人信息</p><br>
      己合非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：<br>
      1、访问和更正您的个人信息<br>
      除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：<br>
      1)	您的账号信息：PC端您可以在“我的”页面的“账户设置”菜单中查阅您提交给己合的个人信息，你也可通过上述途径更新除实名认证信息之外的其他个人信息，您的实名认证信息是您通过实名认证时使用的姓名和身份证信息，如您需要变更您的实名认证信息，您可移动端访问具体路径为：首页-“我的”进入我的资料--右上角“设置”进入账号设置—点击头像进入个人信息页账号密码、电话号码、安全信息：首页--“我的”进入我的--右上角“设置按钮”--账户与安全；<br>
      （2）您的收货信息：移动您可以通过访问“我的”页面的“我的地址”-“收货地址”菜单中随时添加、更改、删除您的收货地址信息（包括收货人姓名、收货地址、收货人的电话号码或邮箱）；您也可以将最常用的收货地址设置为默认地址，如此，若您下次购买商品时未更改收货地址，您的商品会配送到该默认地址。<br>
      （3）您的订单信息：移动端首页--“我的”进入我的己合--我的订单/待收款/待收货/退换售后。您可以选择删除已经完成和已取消的订单来删除您的订单信息，但这样可能导致我们无法准确判断您的购买信息而难以提供相应的售后服务，您也无法对删除的订单使用我们的评价功能。<br>
      对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。<br>
      2、删除您的个人信息<br>
      您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。<br>
      在以下情形中，您可以向我们提出删除个人信息的请求：<br>
      （1）如果我们处理个人信息的行为违反法律法规；<br>
      （2）如果我们收集、使用您的个人信息，却未征得您的同意；<br>
      （3）如果我们处理个人信息的行为严重违反了与您的约定；<br>
      （4）如果您不再使用我们的产品或服务，或您注销了己合账号；<br>
      （5）如果我们终止服务及运营，或者保存期限已届满。<br>
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。<br>
      请您注意，当您成功注销己合账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。<br>
      3、改变您授权同意的范围或撤回您的授权<br>
      您可以通过删除信息、关闭设备功能、在己合网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账号的方式，撤回我们继续收集您个人信息的全部授权。<br>
      请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。<br>
      4、获取您的个人信息副本<br>
      您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供相应的个人信息副本。<br>
      5、响应您的请求<br>
      如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为己合存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至info@jiihee.com或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br>
<br>
      <p>六、未成年人的个人信息保护</p><br>
      1、己合非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。己合根据国家相关法律法规的规定保护未成年人的个人信息。<br>
      2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br>
      3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。<br>
      4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：<br>
      （1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。<br>
      （2）当您作为监护人为被监护的儿童选择使用己合相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。<br>
      （3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。<br>
<br>
      <p>七、通知和修订</p><br>
      1、为给您提供更好的服务以及随着己合业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在己合网站、己合移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问己合以便及时了解最新的隐私政策。<br>
      2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。<br>
      本隐私政策所指的重大变更包括但不限于：<br>
      （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br>
      （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br>
      （3）个人信息共享、转移或公开披露的主要对象发生变化；<br>
      （4）您参与个人信息处理方面的权利及其行使方式发生重大变化；<br>
      （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br>
      （6）个人信息安全影响评估报告表明存在高风险时。<br>
      3、我们还会将本隐私政策的旧版本存档，供您查阅。<br>
      4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。您可以在己合APP通过“我的-设置-隐私政策”中查看本政策。<br>
<br>
      <p>八、如何联系我们</p><br>
      1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。<br>
      个人信息保护负责人邮箱info@jiihee.com<br>
      联系地址：福建省厦门市集美区海西名车Mall 6楼<br>
      2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。<br>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value2: '',
    }
  }
}

</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hear{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.message{
  padding: 20px;
  padding-top: 120px;
  p{
    font-weight: 600;
    font-size: 26px;
  }
}
.title{
  font-weight: 650;
}
</style>
